import styled from "styled-components"

const PageTitle = styled.h1`
  font-family: 'Teko', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 60px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 40px;
  }
`

export default PageTitle
