import React from "react"
import styled from "styled-components"
import SEO from "@/components/SEO"
import { PageRendererProps } from "gatsby"
import PageTitle from '@/components/PageTitle'

type CorporateProfilePageProps = PageRendererProps

const title = '会社概要'
const description = '株式会社ゼンアーキテクツの会社概要です。'

const CorporateProfile: React.FC<CorporateProfilePageProps> = ({ location }) => (
  <>
    <SEO title={title} description={description} pathname={location.pathname} />
    <Root>
      <PageTitle>Corporate Profile</PageTitle>
      <Description>会社概要</Description>
      <Content>
        <Row>
          <Heading>会社名</Heading>
          <Data>
            株式会社ゼンアーキテクツ<br />
            （ZEN ARCHITECTS Co.,Ltd.）
          </Data>
        </Row>
        <Row>
          <Heading>所在地</Heading>
          <Data>
            〒106-0032 東京都港区六本木4-5-8 MH六本木 4F
          </Data>
        </Row>
        <Row>
          <Heading>設立</Heading>
          <Data>
            2003年6月
          </Data>
        </Row>
        <Row>
          <Heading>資本金</Heading>
          <Data>
            1,000万円
          </Data>
        </Row>
        <Row>
          <Heading>役員</Heading>
          <Data>
            代表取締役: 三宅 和之
          </Data>
        </Row>
        <Row>
          <Heading>主要株主</Heading>
          <Data>
            三宅 和之
          </Data>
        </Row>
        <Row>
          <Heading>サービス</Heading>
          <Data>
            Microsoft Azureの導入・移行支援<br />
            Azure / GitHub 関連技術サポート<br />
            DevOps / アジャイル導入支援<br />
          </Data>
        </Row>
        <Row>
          <Heading>主要取引先</Heading>
          <Data>
            日本マイクロソフト株式会社<br />
            GitHub, Inc.<br />
            富士フイルムソフトウエア株式会社<br />
            JFEエンジニアリング株式会社<br />
            株式会社 北國銀行<br />
            株式会社アイキューブドシステムズ<br />
            株式会社 log build<br />
            日鉄日立システムエンジニアリング株式会社<br />
            株式会社ヤプリ<br />
            ヤンマーホールディングス株式会社<br />
            株式会社ベーシック<br />
            トレノケート株式会社<br />
            株式会社 豆蔵<br />
            他（敬称略、順不同）
          </Data>
        </Row>
        <Row>
          <Heading>認定・受賞</Heading>
          <Data>
            Microsoft Azure Advanced Specialization<br />
            Microsoft Gold Cloud Platform コンピテンシー<br />
            GitHub Verified Partner<br />
            GitHub Security Partner<br />
            マイクロソフト ジャパン パートナー オブ ザ イヤー 2021 受賞<br />
          </Data>
        </Row>
      </Content>
    </Root>
  </>
)

const Root = styled.div`
  background-color: #fff;

  @media ${(props) => props.theme.maxSmDown} {
    padding: 80px 0 30px;
  }
  @media ${(props) => props.theme.minMdUp} {
    padding: 130px 15px 100px;
  }
`

const Description = styled.p`
  margin: -20px auto 0;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;

  @media ${(props) => props.theme.minMdUp} {
    max-width: 720px;
    padding: 20px 0 40px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 15px 30px 15px;
  }
`

const Content = styled.div`
  margin: 0 auto;

  @media ${(props) => props.theme.minMdUp} {
    padding: 20px;
    max-width: 720px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 20px;
    font-size: 14px;
  }
`

const Row = styled.div`
  display: flex;

  & + & {
    margin-top: 2px;
  }
`

const Heading = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.minMdUp} {
    padding: 14px;
    width: 130px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 10px;
    width: 80px;
  }
`

const Data = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    margin-left: 10px;
    padding: 14px 10px;
    width: calc(100% - 130px);
    line-height: 1.5;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin-left: 5px;
    padding: 10px;
    width: calc(100% - 80px);
    line-height: 1.5;
  }

  small {
    @media ${(props) => props.theme.minMdUp} {
      font-size: 12px;
    }

    @media ${(props) => props.theme.maxSmDown} {
      font-size: 10px;
    }
  }
`

export default CorporateProfile
